import React, { useEffect } from "react";
import { NavbarTitle, NavbarSubtitle, FlexRow } from "../utilities/exports";

const Navbar = ({ mobile }) => {
  const [display, setDisplay] = React.useState("");
  useEffect(() => {
    document.addEventListener("scroll", (e) => {
      if (window.scrollY > 20) {
        setDisplay("border-b-[2px] !bg-[#f8f9ff9a]");
      } else {
        setDisplay("!bg-[#f8f9ff]");
      }
    });
  }, []);
  return (
    <>
      <FlexRow
        className={`!justify-center md:!justify-between !py-3 !px-24 !fixed !top-0 backdrop-blur-md transform-gpu transition-colors duration-700 !z-50 ${display}`}
      >
        <FlexRow className="!items-start !justify-start !w-auto !gap-3">
          <img
            src={require("../assets/csi-logo.png")}
            alt=""
            className="w-[auto] h-[35px]"
          />
          <NavbarTitle text="CSI KJSCE" />
        </FlexRow>
        <NavbarSubtitle text="Last Date to Apply 17th June" />
      </FlexRow>
    </>
  );
};

export default Navbar;
