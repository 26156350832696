import React from "react";
import { FlexRow } from "../utilities/exports";

const Footer = ({ mobile }) => {
  const display = !mobile ? "" : "";
  const classes = `!w-screen !justify-center !items-center !py-3 !px-10 !sticky !bottom-0 !z-50 border-t-[2px] ${display}`;
  return (
    <>
      <FlexRow className={classes}>
        <h3 className="font-normal text-[#2D2D2D] leading-relaxed text-[1rem]">
          Made with 💖 by CSI-KJSCE
        </h3>
      </FlexRow>
    </>
  );
};

export default Footer;
